import React, { useEffect, useState } from "react";
import Dashboard from "../component/dashboard";
import AppMetaHeader from "../component/AppMetaHeader";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import RecentActivity from "../component/dashboard/RecentActivity";
import { useAuthStore } from "../store/auth";
import { useSearch } from "@tanstack/react-router";
import { useApi, useMount } from "../hooks";
import { IUser } from "../interfaces";
import { City, State } from "country-state-city";
import api from "../config/api";
import showNotification from "../utils/notify";
import { BATCHES, EDUCATION, GENDER2 } from "../utils/constants/data";

export default function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stateId, setStateId] = useState<any>("");
  const [allCity, setAllCity] = useState<any>("");
  const ALLSTATES = State.getStatesOfCountry("IN");
  const [formLoading, setFormLoading] = useState(false);
  const { data, isLoading, load } = useApi<IUser>(`user`);
  const [form] = Form.useForm();

  useMount(() => {
    load();
  }, []);

  useEffect(() => {
    if (
      data?.profile?.state === null ||
      data?.profile?.city === null ||
      data?.profile === null
    )
      setIsModalOpen(true);
  }, [data]);

  useEffect(() => {
    form.setFieldsValue({ city: allCity[0]?.name! });
  }, [allCity]);

  const { user, token } = useAuthStore();
  const userName = user?.name?.split(" ")[0] || "";

  const { redirect } = useSearch({
    from: "",
  });

  const search = useSearch({ from: "" });
  const isAuth = search.isAuth;

  useEffect(() => {
    document.title =
      "Khan Global Studies Dashboard: Login and Register Here.!!";
  }, []);

  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.delete("redirect");
  urlSearchParams.delete("isAuth");
  const newSearchParams = urlSearchParams.toString();

  useEffect(() => {
    if (token && redirect && isAuth) {
      window.location.href = `${redirect}?auth_token=${token}&${newSearchParams}`;
    }
  }, [token, redirect]);

  // const { data } = api.get<IUser>(`user`);

  const filterOption = (input, option) => {
    return option.name.toLowerCase().includes(input.toLowerCase());
  };

  useEffect(() => {
    setAllCity(City.getCitiesOfState("IN", stateId));
  }, [stateId]);

  const onFinish = async (values: any) => {
    try {
      setFormLoading(true);
      await api.post(`/user`, values);
      showNotification("success", "State & City Updated Successfully", 3);
      setFormLoading(false);
      setIsModalOpen(false);
    } catch (error) {}
  };

  return (
    <>
      <AppMetaHeader
        title={`Hello ${userName}, Welcome back!`}
        currentPageTitle="Your Dashboard Today"
      />
      <Row>
        <Col xl={19} lg={19} md={24} xs={24} sm={24}>
          <Dashboard />
        </Col>
        <Col lg={5} xl={5} md={0} sm={0} xs={0}>
          <RecentActivity />
        </Col>
      </Row>

      <Modal
        open={isModalOpen}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        closeIcon={false}
        centered
      >
        <h2 style={{ color: "red", fontWeight: "600" }}>
          Please Update Details
        </h2>

        <Form
          layout="vertical"
          name="update-profile"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            name="state"
            label={"State"}
            rules={[
              {
                required: true,
                message: "Please select state!",
              },
            ]}
          >
            <Select
              size="large"
              id="state"
              onSelect={(value, option) => {
                setStateId(option?.isoCode);
              }}
              filterOption={filterOption}
              fieldNames={{ label: "name", value: "name" }}
              showSearch
              options={ALLSTATES}
              placeholder="Please select your state"
            />
          </Form.Item>

          <Form.Item
            label="City"
            name="city"
            rules={[
              {
                required: true,
                message: "Please select city!",
              },
            ]}
          >
            <Select
              size="large"
              id="city"
              showSearch
              filterOption={filterOption}
              fieldNames={{ label: "name", value: "name" }}
              options={allCity}
              placeholder="Please select your city"
            ></Select>
          </Form.Item>

          <Form.Item
            name="gender"
            label="Select Gender"
            rules={[
              {
                required: true,
                message: "Please input your Gender!",
              },
            ]}
            initialValue={user?.profile?.gender}
            hidden={user?.profile?.gender ? true : false}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Please select your gender"
              defaultValue={[]}
              options={GENDER2}
              size="large"
            />
          </Form.Item>

          <Form.Item
            name="interested_in"
            label="Interested"
            rules={[
              {
                required: true,
                message: "Please input your Interested!",
              },
            ]}
            initialValue={user?.profile?.interested_in}
            hidden={user?.profile?.interested_in ? true : false}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Please select your interest"
              defaultValue={[]}
              options={BATCHES}
              size="large"
            />
          </Form.Item>

          <Form.Item
            name="education"
            label="Highest Education"
            rules={[
              {
                required: true,
                message: "Please input your Education!",
              },
            ]}
            initialValue={user?.profile?.education}
            hidden={user?.profile?.education ? true : false}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Please select your Education"
              defaultValue={[]}
              options={EDUCATION}
              size="large"
            />
          </Form.Item>

          <Form.Item>
            <Button
              loading={formLoading}
              disabled={formLoading}
              type="primary"
              htmlType="submit"
              size="large"
              className="w-100"
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
