import React, { useEffect, useState } from "react";
import { Col, Card, Row, Form, Input, Button, Checkbox, Select } from "antd";
import api from "../../config/api";
import showNotification from "../../utils/notify";
import { useForm } from "antd/es/form/Form";
import { useApi, useMount } from "../../hooks";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import TextArea from "antd/es/input/TextArea";
import { State, City } from "country-state-city";

const AddressDetails = () => {
  const { data, load } = useApi<any>("user");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [form] = useForm();

  const [CSId, setCSId] = useState<any>("DL");
  const [PSId, setPSId] = useState<any>();
  const [disableForm, setDisableForm] = useState();
  const [allCity, setAllCity] = useState<any>();
  const ALLSTATES = State.getStatesOfCountry("IN");

  useMount(() => {
    load();
  });

  useEffect(() => {
    setAllCity(City.getCitiesOfState("IN", CSId));
  }, [CSId]);

  useMount(() => {
    const address = data?.address;
    if (address) {
      const { c_state_id, p_state_id } = address;
      setCSId(c_state_id);
      setPSId(p_state_id);
      form.setFieldsValue(address);
    }
  }, [data]);

  const handleSameAs = ({ target }: CheckboxChangeEvent) => {
    setDisableForm(target?.checked);
    if (target.checked) {
      const c_state_id = form.getFieldValue("c_state_id");
      setPSId(CSId);
      form.setFieldsValue({
        p_address: form.getFieldValue("c_address"),
        p_state_id: c_state_id,
        p_city_id: form.getFieldValue("c_city_id"),
        p_code: form.getFieldValue("c_code"),
      });
    }
  };

  const handleSubmit = async () => {
    try {
      setSubmitLoader(true);
      await api.post(`/user/address`, form.getFieldsValue());
      showNotification("success", "Address Updated Successfully", 3);
      setSubmitLoader(false);
    } catch (err) {
      setSubmitLoader(false);
    }
  };
  const filterOption = (input, option) => {
    return option.name.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
        <Card>
          <Form layout="vertical" form={form} style={{ width: "90%" }}>
            <Form.Item
              label="Current Address"
              name="c_address"
              rules={[
                {
                  required: true,
                  message: "Please enter your current address!",
                },
                {
                  pattern: /^[A-Za-z0-9\s.,!?'"()-]+$/,
                  message:
                    "Please use only English letters (A-Z), numbers (0-9).",
                },
              ]}
            >
              {/* <Input
                size="large"
                id="c_address"
                type="text"
                placeholder="House & Street"
              /> */}
              <TextArea
                rows={4}
                placeholder="Please enter your current address!"
              />
            </Form.Item>

            <Row justify={"space-between"}>
              <Col span={11}>
                <Form.Item
                  label="State"
                  name="c_state_id"
                  rules={[
                    {
                      required: true,
                      message: "Please select state!",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    id="c_state_id"
                    onSelect={(value, option) => {
                      console.log({ option });
                      setCSId(option?.isoCode);
                    }}
                    filterOption={filterOption}
                    fieldNames={{ label: "name", value: "name" }}
                    showSearch
                    options={ALLSTATES}
                  />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  label="City"
                  name="c_city_id"
                  rules={[
                    {
                      required: true,
                      message: "Please select city!",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    id="c_city_id"
                    showSearch
                    filterOption={filterOption}
                    fieldNames={{ label: "name", value: "name" }}
                    // options={CITIES.filter((city) => city.state_id === CSId)}
                    options={allCity}
                  ></Select>
                </Form.Item>
              </Col>

              <Col span={11}>
                <Form.Item
                  label="Pin Code"
                  name="c_code"
                  rules={[
                    {
                      required: true,
                      message: "Please enter current pincode!",
                    },
                  ]}
                >
                  <Input size="large" id="c_code" type="number" maxLength={6} />
                </Form.Item>
              </Col>
            </Row>
            <Checkbox onChange={handleSameAs}>Same as Current Address</Checkbox>

            <Form.Item
              label="Permanent Address"
              name="p_address"
              rules={[
                {
                  required: true,
                  message: "Please enter your current address!",
                },
                {
                  pattern: /^[A-Za-z0-9\s.,!?'"()-]+$/,
                  message:
                    "Please use only English letters (A-Z), numbers (0-9).",
                },
              ]}
            >
              {/* <Input
                size="large"
                id="p_address"
                // onChange={onChange}
                placeholder="House & Street"
                disabled={disableForm ? true : false}
              /> */}
              <TextArea
                rows={4}
                placeholder="Please enter your current address!"
                disabled={disableForm ? true : false}
              />
            </Form.Item>
            <Row justify={"space-between"}>
              <Col span={11}>
                <Form.Item
                  label="State"
                  name="p_state_id"
                  rules={[
                    {
                      required: true,
                      message: "Please select permanent state!",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    onSelect={(value, option) => setPSId(option?.isoCode)}
                    id="p_state_id"
                    filterOption={filterOption}
                    fieldNames={{ label: "name", value: "name" }}
                    showSearch
                    options={ALLSTATES}
                    disabled={disableForm ? true : false}
                  />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  label="City"
                  name="p_city_id"
                  rules={[
                    {
                      required: true,
                      message: "Please select permanent city!",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    id="p_city_id"
                    fieldNames={{ label: "name", value: "name" }}
                    filterOption={filterOption}
                    showSearch
                    // options={CITIES.filter((city) => city.state_id === PSId)}
                    options={allCity}
                    disabled={disableForm ? true : false}
                  />
                </Form.Item>
              </Col>

              <Col span={11}>
                <Form.Item
                  label="Pin Code"
                  name="p_code"
                  rules={[
                    {
                      required: true,
                      message: "Please enter permanent pin code!",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    id="p_code"
                    type="number"
                    maxLength={6}
                    disabled={disableForm ? true : false}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={submitLoader}
                disabled={submitLoader}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default AddressDetails;
