import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { IPaginate, IQuiz, IReport, IScore } from "../interfaces/quiz";

interface IQuizStore {
  quiz?: IQuiz;
  status: Record<number, any>;
  getReport: () => IReport;
  getScore: () => IScore;
  paginate: (pageId) => IPaginate;
  updateQuestion: (questionId: number, index: number) => void;
  updateMCQQuestion: (questionId: number, index: number) => void;
  updateVisited: (id) => void;
  updateBookmark: (id) => void;
  setQuiz: (quiz: IQuiz) => void;
}
type TSetStore = (
  partial:
    | IQuizStore
    | Partial<IQuizStore>
    | ((state: IQuizStore) => IQuizStore | Partial<IQuizStore>),
  replace?: boolean | undefined
) => void;

enum QuestionType {
  scq = 1,
  bq,
  msq,
  ibq,
}

export function isEqual(a, b) {
  const format = (s) =>
    `${s}`
      .split(",")
      .sort((a, b) => +a - +b)
      .join(",");
  return format(+a) === format(+b);
}

Boolean.prototype.toString = function (this: string) {
  // console.log("boolean casting");
  return JSON.parse(this);
};

const quizStore = (set: TSetStore, get: () => IQuizStore) => {
  return {
    quiz: undefined,
    status: {},
    getReport: () => {
      const { status, quiz } = get();
      const total = quiz?.questions.length!;

      const { visited, answered, bookmarked, correct, score } = Object.values(
        status
      ).reduce(
        (acc, { visited, attempt, bookmark, answer, type }) => {
          const marking = quiz?.meta.marking?.[QuestionType[type]] || {
            correct: quiz?.meta.correct_mark,
            incorrect_mark: quiz?.meta.incorrect_mark,
          };

          const matched = isEqual(attempt, answer);

          const score = +(
            `${attempt}` &&
            +matched * marking.correct - +!matched * marking.incorrect_mark
          );

          return {
            correct: acc.correct + +matched,
            visited: acc.visited + +visited,
            answered: acc.answered + +!!`${attempt}`,
            bookmarked: acc.bookmarked + +bookmark,
            score: acc.score + score,
          };
        },
        { visited: 0, answered: 0, bookmarked: 0, correct: 0, score: 0 }
      );

      return {
        total,
        notVisited: total - visited,
        visited,
        answered,
        bookmarked,
        notAnswered: total - answered,
        correct,
        inCorrect: answered - correct,
        score,
      };
    },
    getScore: () => {
      const { quiz, getReport } = get();
      const meta = quiz?.meta;
      const correctMark = +(meta?.correct_mark || 1);
      const { total, correct, inCorrect, notVisited, visited, score } =
        getReport();
      const totalMarks = total * correctMark;

      return {
        totalScore: score.toFixed(2),
        correct,
        inCorrect,
        totalMarks: +totalMarks.toFixed(2),
        visited,
        notVisited,
      };
    },
    setQuiz: async (quiz: IQuiz) => {
      const status: Record<number, any> = {};
      quiz.questions.forEach((item, index) => {
        status[item.id] = {
          visited: false,
          bookmark: false,
          attempt: [],
          position: index + 1,
          answer: item.answer,
          type: item.type,
        };
      });
      set({
        quiz,
        status,
      });
    },

    updateQuestion: (questionId: number, value: any) => {
      const status = get().status;
      status[questionId].attempt = value;
      set({
        status,
      });
    },

    updateMCQQuestion: (questionId: number, value: any) => {
      const status = get().status;
      const isValuePresent = status[questionId].attempt.includes(value);

      if (isValuePresent) {
        const updatedAttempt = status[questionId].attempt.filter(
          (option) => option !== value
        );
        status[questionId].attempt = updatedAttempt;
        set({
          status,
        });
      } else {
        const updatedAttempt = [...status[questionId].attempt, value];
        status[questionId].attempt = updatedAttempt;
        set({
          status,
        });
      }
    },

    updateBookmark: (id: number) => {
      const status = get().status;
      status[id].bookmark = !status[id].bookmark;
      set({ status });
    },
    updateVisited: (id: number) => {
      const status = get().status;
      status[id].visited = true;
      set({ status });
    },
    paginate: (page = 1) => {
      const questions = get().quiz?.questions!;
      const qpp = Number(get().quiz?.meta.qpp || "10");
      var offset = (page - 1) * qpp,
        paginatedItems = questions?.slice(offset).slice(0, qpp),
        total_pages = Math.ceil(questions.length / qpp);
      return {
        page,
        qpp,
        pre_page: page - 1,
        next_page: total_pages > page ? page + 1 : null,
        total: questions.length,
        totalPages: total_pages,
        questions: paginatedItems,
      };
    },
  };
};

const useQuizStore = create(
  persist<IQuizStore>(quizStore, {
    name: "QUIZ_STORE",
    storage: createJSONStorage(() => sessionStorage),
  })
);

export default useQuizStore;

export const useTimerStore = create(
  persist(
    (set, get) => ({
      timeList: null,
      storeTimer: (time) => {
        set({ timeList: time });
      },
    }),
    {
      name: "timer-storage",
    }
  )
);
