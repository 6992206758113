import React from "react";
import parse from "html-react-parser";
import useQuizStore from "../../../store/quizStore";

export const Option = ({
  elem,
  item,
  i,
  updateQuestion,
  updateMCQQuestion,
}) => {
  const status = useQuizStore((state) => state.status);
  const answer = status[item.id].attempt;

  if (!elem) return;

  return (
    <>
      <div
        className={
          Array.isArray(answer)
            ? answer.includes(i + 1)
              ? "blueColor"
              : "quiz-options-wrapper"
            : answer !== i + 1
            ? "quiz-options-wrapper"
            : "blueColor"
        }
        onClick={() => {
          if (answer === i + 1) {
            updateMCQQuestion && updateMCQQuestion(item?.id, []);
            updateQuestion && updateQuestion(item?.id, []);
          } else {
            updateMCQQuestion && updateMCQQuestion(item?.id, i + 1);
            updateQuestion && updateQuestion(item?.id, i + 1);
          }
        }}
      >
        <div
          style={{ margin: "15px 15px" }}
          className={`
          custom-btn
          ${
            Array.isArray(answer)
              ? answer.includes(i + 1) && "quiz-start-btn-self_1"
              : answer === i + 1 && "quiz-start-btn-self_1"
          }`}
        >
          <b>{(i + 10).toString(36).toUpperCase()}</b>
        </div>
        <div className="quiz-options">
          <p
            style={{
              paddingLeft: "8px",
              marginTop: "0.8rem",
            }}
          >
            <p>{parse(String(elem))}</p>
          </p>
        </div>
      </div>
    </>
  );
};
