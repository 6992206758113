import { Button, Col } from "antd";
import React from "react";
import useQuizStore, { isEqual } from "../../store/quizStore";

const getIndexClass = (status: any, i) => {
  const { answer, attempt } = status;

  if (!`${attempt}`) return "currentQuestion";
  if (isEqual(attempt, answer)) return "question-attempted";
  return "question-incorrect";
};

export function QuestionGraph({ data, navigate }) {
  const { status } = useQuizStore();
  return (
    <Col span={8} style={{ backgroundColor: "#F5F5F5", borderRadius: "5px" }}>
      <div
        style={{
          width: "80%",
          margin: "auto",
          marginTop: "3%",
        }}
      >
        <p>Select which Question number you want to see.</p>
        <div className="quiz-result-list">
          {data?.map((item, i) => {
            const currentStatus = status[item.id];
            const qType = item.type;
            return (
              <Button
                key={i}
                onClick={() => {
                  navigate(currentStatus.position);
                }}
                className={`quiz-result-btn ${getIndexClass(currentStatus, i)}`}
              >
                {i + 1}
              </Button>
            );
          })}
        </div>
      </div>
    </Col>
  );
}
